/**
 * @description GA4(Google Analytics 4) 데이터 전송을 위한 공통 스크립트
 * 아래 자바스크립트는 HTML 상단에 위치하므로, function 정의만 존재한다.
 * @author Cora
 * @since 2023.08.25
 */

function push(filledObject) {
    if (!window.dataLayer) {
        window.dataLayer = [];
    }
    const removedEmpty = removeEmptyProperties(filledObject);
    window.dataLayer.push(removedEmpty);
    // console.log(window.dataLayer);
}

function removeEmptyProperties(o) {
    return Object.entries(o)
        .filter(([_, v]) => (v != null && v != ""))
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    // 재귀 버전 - Array 타입이 Object 로 변형되는 오류가 있음.
    // return Object.entries(o)
    //     .filter(([_, v]) => (v != null && true))
    //     .reduce(
    //         (acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? removeEmptyProperties(v) : v }),
    //         {}
    //     );
}

/**
 * TODO : Page 정의는 공통화되지 않음. GA4 전용 까지
 * @typedef {Object} Page - 페이지 정보 GA용
 * @property {?string} [ep_page_0depth] - 페이지 방문 채널
 * @property {?string} [title] - 페이지 타이틀
 * @property {?string} [ep_page_1depth] - 페이지 1Depth
 * @property {?string} [ep_page_2depth] - 페이지 2Depth
 * @property {?string} [ep_page_3depth] - 페이지 3Depth
 * @property {?string} [ep_page_4depth] - 페이지 4Depth
 * @property {?string} [ep_page_day] - 요일
 * @property {?string} [ep_page_dspctgryno] - 카테고리 번호
 * @property {?string} [ep_page_dspctgryname] - 카테고리 이름
 * @property {?string} [ep_page_brndshopid] - 브랜드샵 ID
 * @property {?string} [ep_page_brndshopname] - 브랜드샵 이름
 * @property {?string} [ep_page_productbrand] - 상품 상세 브랜드
 * @property {?string} [ep_page_productcode] - 상품 상세 코드
 * @property {?string} [ep_page_productname] - 상품 상세 이름
 * @property {?string} [ep_page_eventcode] - 이벤트 코드
 * @property {?string} [ep_page_eventtitle] - 이벤트 제목
 * @property {?string} [ep_page_specialcode] - 기획전 코드
 * @property {?string} [ep_page_specialbrand] - 기획전 브랜드
 * @property {?string} [ep_page_specialtitle] - 기획전 제목
 * @property {?string} [ep_page_promtSn] - 매거진 코드
 * @property {?string} [ep_page_promtNm] - 매거진 이름
 * @property {?string} [ep_page_godNo] - 상품 번호
 * @property {?string} [ep_page_godNm] - 상품 이름
 * @property {?string} [ep_page_moviSj] - 동영상 제목
 * -- 검색 관련 -- 이벤트 매개변수(EventDefault)랑 중복 발생... 어쩔 수 없음
 * @property {?string} [event] - ga_virtual
 * @property {?string} [location] - URL
 * @property {?string} [ep_search_searchwords] - 검색어
 */

/**
 * @typedef {Object} User - 사용자 정보
 * @property {?string} [ga_cid] - 고객 GA CID
 * @property {?string} [channel] - 채널 유형(PC/MO/APP)
 * @property {?string} [login] - 로그인 여부
 * @property {?string} [isMember] - 회원 여부
 * @property {?string} [login_type] - 로그인 유형
 * @property {?string} [join_date] - 회원 가입 일자
 * @property {?string} [grade] - 등급
 * @property {?string} [age] - 연령대
 * @property {?string} [gender] - 성별
 * @property {?string} [agree_sms] - SMS 동의 여부
 * @property {?string} [agree_email] - 이메일 동의 여부
 */

/**
 * @typedef {Object} User_GA - 사용자 정보
 * @property {?string} [up_cid] - 고객 GA CID
 * @property {?string} [up_channel] - 채널 유형(PC/MO/APP)
 * @property {?string} [up_loginyn] - 로그인 여부
 * @property {?string} [up_memberny] - 회원 여부
 * @property {?string} [up_logintype] - 로그인 유형
 * @property {?string} [up_joindate] - 회원 가입 일자
 * @property {?string} [up_grade] - 등급
 * @property {?string} [up_agerange] - 연령대
 * @property {?string} [up_gender] - 성별
 * @property {?string} [up_smsyn] - SMS 동의 여부
 * @property {?string} [up_emailyn] - 이메일 동의 여부
 */

/**
 * @param {Page} filledPageInfo
 * @returns {Page}
 */
function basePageInfo(filledPageInfo) {
    const BASE_PAGE = {
        title: null,
        ep_page_0depth: null,
        ep_page_1depth: null,
        ep_page_2depth: null,
        ep_page_3depth: null,
        ep_page_4depth: null,
        ep_page_day: null,
        ep_page_dspctgryno: null,
        ep_page_dspctgryname: null,
        ep_page_brndshopid: null,
        ep_page_brndshopname: null,
        ep_page_productbrand: null,
        ep_page_productcode: null,
        ep_page_productname: null,
        ep_page_eventcode: null,
        ep_page_eventtitle: null,
        ep_page_specialcode: null,
        ep_page_specialbrand: null,
        ep_page_specialtitle: null,
        ep_page_promtSn: null,
        ep_page_promtNm: null,
        ep_page_godNo: null,
        ep_page_godNm: null,
        ep_page_moviSj: null,
        event: null,
        location: null,
        ep_search_searchwords: null,
    }
    /** @type {Page} **/
    return Object.assign({}, BASE_PAGE, filledPageInfo);
}

/**
 * @typedef {Object} PAGE_REGEX
 * @property {string} depth1
 * @property {?string} [depth2]
 * @property {?string} [depth3]
 * @property {?string} [depth4]
 * @property {?boolean} [isVirtual]
 * @property {?RegExp} [regex]
 */
/** @type {Array.<PAGE_REGEX>} **/
const PAGE_REGEX_LIST = [
    // SSF SHOP MOBILE //
    {depth1: "메인", depth2: null, depth3: null, depth4: null, isVirtual: false, regex: /\.com:?[0-9]*?(\/main$|\/main\?\w*)/},
    {depth1: "세사패TV", depth2: null, depth3: null, depth4: null, isVirtual: false, regex: /\/display\/ssftv\/ssfTV/},
    {depth1: "세사패TV", depth2: "세사패 LIVE", depth3: "{{라이브명}}", depth4: null, isVirtual: false, regex: /\/display\/ssftv\/live\?\w+/},
    {depth1: "세사패TV", depth2: "상세보기", depth3: "{{라이브명}}", depth4: null, isVirtual: false, regex: /\/display\/ssftv\/\d+\/ssfTVMoviDetail/},
    {depth1: "선물하기", depth2: null, depth3: null, depth4: null, isVirtual: false, regex: /\/display\/gift/},
    {depth1: "선물하기", depth2: "{{카테고리명}}", depth3: null, depth4: null, isVirtual: false, regex: /\/all\/list\?dspCtgryNo=\w+&giftPageYn=Y/},
    {depth1: "브랜드", depth2: "브랜드리스트", depth3: null, depth4: null, isVirtual: true, regex: /\/public\/display\/lnb\/v2\/getLnbList/},
    {depth1: "브랜드", depth2: "메인", depth3: "{{브랜드명}}", depth4: null, isVirtual: false, regex: /\/[a-zA-Z0-9`~!@#$%^&*()-_=+|[\]{};:'",.<>/?]+\/main\?dspCtgryNo=[a-zA-Z0-9]*&brandShopNo=[a-zA-Z0-9]+/},
    {depth1: "브랜드", depth2: "메인", depth3: "{{브랜드명}}", depth4: null, isVirtual: false, regex: /\/[a-zA-Z0-9`~!@#$%^&*()-_=+|[\]{};:'",.<>/?]+\/main\?brandShopNo=[a-zA-Z0-9]+/},
    {depth1: "브랜드", depth2: "상품리스트", depth3: "{{브랜드명}}", depth4: "{{카테고리명}}", isVirtual: false, regex: /\/[a-zA-Z0-9`~!@#$%^&*()-_=+|[\]{};:'",.<>/?]+\/[a-zA-Z-_]+\/list\?dspCtgryNo=\w+&brandShopNo=\w+&brndShopId=\w+/},
    {depth1: "카테고리", depth2: "메인", depth3: "{{카테고리명}}", depth4: null, isVirtual: false, regex: /\/main\/THMA[A-Z0-9]+\?dspCtgryNo=[A-Z0-9]+/},
    {depth1: "카테고리", depth2: "상품리스트", depth3: "{{카테고리명}}", depth4: null, isVirtual: false, regex: /\/[a-zA-Z-_]+\/list\?dspCtgryNo=[a-zA-Z0-9]+/}, 
    {depth1: "랭킹", depth2: "클릭랭킹", depth3: "{{카테고리명}}", depth4: null, isVirtual: false, regex: /rankSect=CLICK_RANK&ctgryFlterCd=[A-Z-_]+&preferAgeCd=\w+&brndShopId=/},
    {depth1: "랭킹", depth2: "클릭랭킹", depth3: "{{카테고리명}}", depth4: null, isVirtual: false, regex: /rankSect=CLICK_RANK&ctgryFlterCd=[A-Z-_]+&preferAgeCd=\w+&brndShopId=/},
    {depth1: "랭킹", depth2: "판매랭킹", depth3: "{{카테고리명}}", depth4: null, isVirtual: false, regex: /rankSect=SALE_RANK&ctgryFlterCd=[A-Z-_]+&preferAgeCd=\w+&brndShopId=/},
    {depth1: "랭킹", depth2: "판매랭킹", depth3: "{{카테고리명}}", depth4: null, isVirtual: false, regex: /rankSect=SALE_RANK&ctgryFlterCd=[A-Z-_]+&preferAgeCd=\w+&brndShopId=/},
    {depth1: "랭킹", depth2: "검색랭킹", depth3: null, depth4: null, isVirtual: false, regex: /rankSect=SEARCH_RANK&ctgryFlterCd=[A-Z-_]+&preferAgeCd=\w+&brndShopId=/},
    {depth1: "랭킹", depth2: "검색랭킹", depth3: null, depth4: null, isVirtual: false, regex: /rankSect=SEARCH_RANK&ctgryFlterCd=[A-Z-_]+&preferAgeCd=\w+&brndShopId=/},
    {depth1: "매거진", depth2: "매거진리스트", depth3: null, depth4: null, isVirtual: false, regex: /\/ssfMgz\/list/},
    {depth1: "매거진", depth2: "상세보기", depth3: "{{매거진명}}", depth4: null, isVirtual: false, regex: /\/ssfMgz\/\d+\/view/},
    {depth1: "기획전", depth2: "브랜드", depth3: "{{브랜드명}}", depth4: null, isVirtual: false, regex: /promtFlterBrndId=\w+/},
    {depth1: "기획전", depth2: "기획전리스트", depth3: null, depth4: null, isVirtual: false, regex: /\/special\/list/},
    {depth1: "기획전", depth2: "상세보기", depth3: "{{기획전명}}", depth4: null, isVirtual: false, regex: /\/special\/\d+\/view\?brndShopId=&brandShopNo=/},
    {depth1: "기획전", depth2: "핫딜", depth3: null, depth4: null, isVirtual: false, regex: /\/special\/\d+\/view$/},
    {depth1: "이벤트", depth2: "이벤트리스트", depth3: null, depth4: null, isVirtual: false, regex: /\/main\/THMA30A05\?section=THMA30A05/},
    {depth1: "이벤트", depth2: "이벤트리스트", depth3: null, depth4: null, isVirtual: false, regex: /\/event\/list/},
    {depth1: "이벤트", depth2: "상세보기", depth3: "{{이벤트명}}", depth4: null, isVirtual: false, regex: /\/event\/EV\d+/},
    {depth1: "검색", depth2: null, depth3: null, depth4: null, isVirtual: true, regex: /\/public\/virtual\/search/},
    {depth1: "검색", depth2: "검색결과", depth3: null, depth4: null, isVirtual: false, regex: /\/public\/search\/search\/view/},
    {depth1: "장바구니", depth2: null, depth3: null, depth4: null, isVirtual: false, regex: /\/public\/cart\/list/},
    {depth1: "상품상세", depth2: "{{상품명}}", depth3: null, depth4: null, isVirtual: false, regex: /\/[a-zA-Z0-9`~!@#$%^&*()-_=+|[\]{};:'",.<>/?]+\/[A-Z0-9]+\/good\?/},
    {depth1: "로그인", depth2: null, depth3: null, depth4: null, isVirtual: false, regex: /\/public\/member\/login/},
    {depth1: "로그인", depth2: "회원가입", depth3: null, depth4: null, isVirtual: false, regex: /\/public\/member\/addMemberStep1/},
    {depth1: "로그인", depth2: "회원가입", depth3: "회원가입 완료", depth4: null, isVirtual: false, regex: /\/secured\/(member\/onlineMemberSucces)|secured\/(mypage\/certifySucess)/},
    {depth1: "주문/결제", depth2: null, depth3: null, depth4: null, isVirtual: false, regex: /\/secured\/order\/new/},
    {depth1: "주문완료", depth2: null, depth3: null, depth4: null, isVirtual: false, regex: /\/secured\/order\/[A-Z0-9]+\/view/},
];

/** @returns {Page} */
function getPageInfo(exceptionCase, codeName) {

    const url = new URL(window.location.href);
    const urlPath = url.pathname.split("/");
    let ctgryCd = "";
    let promtBrndId = "";

    if (exceptionCase) {
        switch (exceptionCase) {
            case "search":
                url.pathname = "/public/virtual/search";
                url.search = "";
                break;
            case "brand":
                url.pathname = "/public/display/lnb/v2/getLnbList/virtual/brand"
            case "ctgryCd":
                ctgryCd = codeName;
                break;
            case "promtBrndId":
                promtBrndId = codeName;
                break;
            default: break;
        }
    }

    /** @type {PAGE_REGEX} **/
    const match = PAGE_REGEX_LIST.find(r => r.regex.test(url.toString()));

    const deviceType = getDeviceType();
    /** @type {Page} **/
    const page = {
        ep_page_day: TODAY,
        ep_page_0depth: deviceType,
        ep_page_1depth: (match ? match.depth1 : null),
        ep_page_2depth: (match ? match.depth2 : null),
        ep_page_3depth: (match ? match.depth3 : null),
        ep_page_4depth: (match ? match.depth4 : null),
        title: (match ? `${deviceType}>${match.depth1 ? match.depth1 : ""}${match.depth2 ? ">"+match.depth2 : ""}${match.depth3 ? ">"+match.depth3 : ""}${match.depth4 ? ">"+match.depth4 : ""}` : deviceType),
    };

    if (match) {
        // 이벤트 상세 페이지 조회 시 이벤트 정보 추가
        if (url.toString().includes("/event/") && url.toString().includes("EV")) {
            page.title = page.title.replace("{{이벤트명}}", document.getElementById("eventNameForGA") ? document.getElementById("eventNameForGA").value : document.getElementsByName('og_title')[0].getAttribute("content"));
            page.ep_page_eventtitle = document.getElementById("eventNameForGA") ? document.getElementById("eventNameForGA").value : document.getElementsByName('og_title')[0].getAttribute("content"); // eventDetail.jsp > ${event.eventExt.evtNm}
            page.ep_page_eventcode = /\/event\/(?<code>EV\d*)/.exec(url.toString()).groups.code;
            page.ep_page_3depth = document.getElementById("eventNameForGA") ? document.getElementById("eventNameForGA").value : document.getElementsByName('og_title')[0].getAttribute("content"); // eventDetail.jsp > ${event.eventExt.evtNm}
        }
        // 기획전 상세 페이지 조회 시 기획전 정보 추가
        if ((match.depth2 !== "핫딜") && url.toString().includes("/special/") && url.toString().includes("/view")) {
            page.title = page.title.replace("{{기획전명}}",document.getElementById("promtNmForGA").value);
            page.ep_page_specialtitle = document.getElementById("promtNmForGA").value; // specialDetail.jsp "${planDetail.dspPromt.promtNm}";
            page.ep_page_specialcode = /special\/(?<code>\d*)\/view*/.exec(url.toString()).groups.code;
            // page.ep_page_specialbrand = ""; // FIXME 화면 내에 브랜드 정보가 없음 (url 에 존재하지 않음)
            page.ep_page_3depth = document.getElementById("promtNmForGA").value;
        }
        // 기획전 리스트 페이지 브랜드명 정보 추가
        if (match.depth1 == "기획전" && match.depth2 == "브랜드") {
            page.ep_page_brndshopid = promtBrndId;
            page.ep_page_3depth = page.ep_page_brndshopid;
            page.title = page.title.replace("{{브랜드명}}", page.ep_page_brndshopid);
        }
        if (url.pathname === "/public/virtual/search" || url.pathname === "/public/display/lnb/v2/getLnbList/virtual/brand") {
            page.event = "ga_virtual";
            page.location = url.href;
        }
        // 하위 카테고리 진입시 카테고리 정보 추가
        if (match.depth1 == "카테고리" && match.depth2 == "메인") {
            page.ep_page_dspctgryname = document.getElementById("dspCtgryNmForGA").value;
            page.ep_page_dspctgryno = url.searchParams.get('dspCtgryNo');
            page.ep_page_3depth = document.getElementById("dspCtgryNmForGA").value;
            page.title = page.title.replace("{{카테고리명}}", document.getElementById("dspCtgryNmForGA").value);
        }
        if (match.depth1 == "카테고리" && match.depth2 == "상품리스트") {
            page.ep_page_dspctgryname = urlPath[1];
            page.ep_page_dspctgryno = url.searchParams.get('dspCtgryNo');
            page.ep_page_3depth = page.ep_page_dspctgryname;
            page.title = page.title.replace("{{카테고리명}}", page.ep_page_dspctgryname);
        }
        // 매거진 상세 페이지 조회 시 매거진 정보 추가
        if (match.depth1 == "매거진" && match.depth2 == "상세보기") {
            page.ep_page_promtNm = document.getElementById("promtNmForGA").value;
            page.ep_page_promtSn = /\/ssfMgz\/(?<code>\d+)\/view/.exec(url.toString()).groups.code;
            page.ep_page_3depth = document.getElementById("promtNmForGA").value;
            page.title = page.title.replace("{{매거진명}}", document.getElementById("promtNmForGA").value);
        }
        // 선물하기 페이지에서 카테고리 조회 시 카테고리 정보 추가
        if (match.depth1 == "선물하기" && match.depth2 !== null) {
            page.ep_page_dspctgryname = document.getElementById("dspCtgryNmForGA").value;
            page.ep_page_dspctgryno = url.searchParams.get('dspCtgryNo');
            page.ep_page_2depth = document.getElementById("dspCtgryNmForGA").value;
            page.title = page.title.replace("{{카테고리명}}", document.getElementById("dspCtgryNmForGA").value);
        }
        // 상품 상세 페이지 조회 시 상품 정보 추가
        if (match.depth1 == "상품상세") {
            page.ep_page_godNo = /\/(?<code>[A-Z0-9]+)\/good/.exec(url.toString()).groups.code;
            page.ep_page_godNm = document.getElementById("godNmForGA") ? document.getElementById("godNmForGA").value : page.ep_page_godNo;
            page.ep_page_2depth = page.ep_page_godNm;
            page.title = page.title.replace("{{상품명}}", page.ep_page_godNm);
            const categoryName = document.getElementById("ctgryNmForGA") ? document.getElementById("ctgryNmForGA").value : null
            if (categoryName) {
                page.ep_page_dspctgryname = categoryName;
            }
        }
        // 브랜드 페이지 조회 시 브랜드 정보 추가
        if (match.depth1 == "브랜드" && match.depth2 == "메인") {
            page.ep_page_brndshopname = unescape(urlPath[1].toUpperCase());
            page.ep_page_brndshopid = url.searchParams.get('brndShopId');
            page.ep_page_3depth = page.ep_page_brndshopname;
            page.title = page.title.replace("{{브랜드명}}", page.ep_page_brndshopname);
        }
        // 브랜드 페이지 조회 후 카테고리 클릭 시 브랜드 및 카테고리 정보 추가
        if (match.depth1 == "브랜드" && match.depth2 == "상품리스트") {
            page.ep_page_brndshopname = unescape(urlPath[1].toUpperCase());
            page.ep_page_dspctgryname = urlPath[2];
            page.ep_page_brndshopid = url.searchParams.get('brndShopId');
            page.ep_page_dspctgryno = url.searchParams.get('dspCtgryNo');
            page.ep_page_3depth = page.ep_page_brndshopname;
            page.ep_page_4depth = page.ep_page_dspctgryname;
            page.title = page.title.replace("{{브랜드명}}", page.ep_page_brndshopname);
            page.title = page.title.replace("{{카테고리명}}", page.ep_page_dspctgryname);
        }
        // 랭킹 페이지 조회 후 카테고리 클릭 시 카테고리 정보 추가
        if (match.depth1 == "랭킹" && (match.depth2 == "클릭랭킹" || match.depth2 == "판매랭킹")) {
            if (match.depth2 == "클릭랭킹") {
                page.ep_page_dspctgryname = ctgryCd ? ctgryCd : url.searchParams.get('ctgryFlterCd');
            }
            if (match.depth2 == "판매랭킹") {
                page.ep_page_dspctgryname = ctgryCd ? ctgryCd : url.searchParams.get('ctgryFlterCd');
            }
            page.ep_page_3depth = page.ep_page_dspctgryname;
            page.title = page.title.replace("{{카테고리명}}", page.ep_page_dspctgryname);
        }
        // 세사패TV 상세보기 페이지 조회 시 세사패 정보 추가
        if (match.depth1 == "세사패TV" && match.depth2 == "상세보기") {
            page.ep_page_moviSj = document.getElementById("moviSjForGA").value;
            page.ep_page_3depth = document.getElementById("moviSjForGA").value;
            page.title = page.title.replace("{{라이브명}}", document.getElementById("moviSjForGA").value);
        }
        // 세사패 TV 라이브 화면 // FIXME 화면에 라이브명 정보가 없어 meta tag 이용. 라이브명 필드를 없애든지 변경 필요.
        if (match.depth1 == "세사패TV" && match.depth2 == "세사패 LIVE") {
            page.ep_page_moviSj = null; // FIXME 들어가야하는지?
            if (document.querySelector("meta[name=og_title]").content) {
                page.ep_page_3depth = document.querySelector("meta[name=og_title]").content;
                page.title = page.title.replace("{{라이브명}}", document.querySelector("meta[name=og_title]").content ?? null);
            } else {
                page.ep_page_3depth = url.searchParams.get("ck");
                page.title = page.title.replace("{{라이브명}}", url.searchParams.get("ck"));
            }
        }
        if (match.depth1 == "검색" && match.depth2 == "검색결과") {
            page.ep_search_searchwords = url.searchParams.get("keyword");
        }
    }
    return basePageInfo(page);
}

const TODAY = getPageViewDay(new Date().getDay());
function getPageViewDay(dayNumber) {
    const days = ["일", "월", "화", "수", "목", "금", "토"];
    return days[dayNumber];
}

function pushPageViewEvent(exceptionCase, codeName) {
    const pageInfo = getPageInfo(exceptionCase, codeName);
    const addedUserInfo = addUserInfo(pageInfo);
    push(addedUserInfo);
}

// 로그인 완료 시 서버에서 user 정보를 내려줌...?
// local storage에 저장 (이미 있는지 확인 후)
// 꺼내서 push. (+ 페이지 정보와 함께)
/**
 * @description
 * @param {User} filledUserInfo
 * @returns {User_GA}
 */
function baseUserInfo(filledUserInfo) {
    const GA_Keys = {
        ga_cid: "up_cid",
        channel: "up_channel",
        login: "up_loginyn",
        isMember: "up_memberny",
        login_type: "up_logintype",
        join_date: "up_joindate",
        grade: "up_grade",
        age: "up_agerange",
        gender: "up_gender",
        agree_sms: "up_smsyn",
        agree_email: "up_emailyn",
    }
    const renamedUserInfo = renameKeys(filledUserInfo, GA_Keys);
    /** @type {User_GA} **/
    const BASE_USER = {
        up_cid: null,
        up_channel: null,
        up_loginyn: null,
        up_memberny: null,
        up_logintype: null,
        up_joindate: null,
        up_grade: null,
        up_agerange: null,
        up_gender: null,
        up_smsyn: null,
        up_emailyn: null,
    }
    /** @type {User_GA} **/
    return Object.assign({}, BASE_USER, renamedUserInfo);
}

function getDeviceType() {
    return fn_getDeviceType() !== "WEB" ? "APP" : "MO";
}

/*
loginAction.json 성공 시,
--> 현재   model 에 mbr 전체가 담겨있음...이거 삭제해야지
--> 필요 정보만 따로 저장한 뒤 login.jsp > function callbackLogin(args) 에서 local storage에 key: user . object 로 담음. (매번 갱신해줄 필요가 있을까?)
ex) window.localStorage.setItem("user", JSON.stringify(args.mbr));

로그인 시 cookie에 MBRNO 생성 로그아웃 시 삭제 됨
로그인 한번이라도 하면, e_mbr 쿠키 생성
memberSexSect : FEMALE/MALE

로그인 타입: callbackLoginAfterKakao 카카오

 */
/**
 * @description 사용자 데이터 반환
 * @returns {User_GA}
 */
function getUserInfo() {
    // JSON 따옴표 제거 '"eyJsb2dpbiI6Ik4ifQ=="' -> 'eyJsb2dpbiI6Ik4ifQ=='
    const userCookie = getUserCookie("MAI");
    if (!userCookie) {
        let user = {
            ga_cid: getUserCookie("_ga"),
            channel: getDeviceType(),
            isMember: getUserCookie("e_mbr") ? "Y" : "N",
            login: "N",
        }
        /** @type {User_GA} **/
        return baseUserInfo(user);
    }
    const cookie = userCookie.replace(/"/g, "");
    const decode = atob(cookie);
    const cookieUser = JSON.parse(decode);
    /** @type {Partial<User>} **/
    let user = {
        ga_cid: getUserCookie("_ga"),
        channel: getDeviceType(),
        isMember: getUserCookie("e_mbr") ? "Y" : "N",
        login: cookieUser.login,
    }
    if (cookieUser.login == "Y") {
        /** @type {Partial<User>} **/
        const loginUser = {
            login_type: localStorage.getItem("LOGIN_TYPE") ?? "통합로그인",
            join_date: cookieUser.join_date,
            grade: USER_GRADE_LIST[cookieUser.grade] ?? "WELCOME",
            age: getUserAgeRange(cookieUser.birth),
            gender: cookieUser.gender === "FEMALE" ? "F" : "M",
            agree_sms: cookieUser.agree_sms,
            agree_email: cookieUser.agree_email,
        }
        user = Object.assign(user, loginUser);
    }
    /** @type {User_GA} **/
    return baseUserInfo(user);
}

function getUserAgeRange(birth) {
    if (!birth) {return "U";}
    const age = new Date().getFullYear() - Number(birth);
    if (!age) {return "U";}
    const unit = 5;
    const result = Math.floor(age/unit);
    switch (true) {
        case (result <= 3): return "19이하";
        case (result >= 12): return "60이상";
        default: return (unit * result) + "~"+ ((unit * (result+1))-1);
    }
}

/** @enum {string} USER_GRADE_LIST **/
const USER_GRADE_LIST = {
    FAM: "FAMILY",
    BRZ: "BRONZE",
    SLVR: "SILVER",
    GLD: "GOLD",
    PLTN: "PLATINUM",
    DIAMD: "DIAMOND",
};

function getUserCookie(cookieName) {
    const value = document.cookie.match('(^|;) ?' + cookieName + '=([^;]*)(;|$)');
    return value? value[2] : null;
}

/**
 * @description 페이지정보+사용자정보
 * @param {Page} pageInfo
 * @returns {Object}
 */
function addUserInfo(pageInfo) {
    return Object.assign({}, pageInfo, getUserInfo());
}

/**
 * @typedef {Object} EventDefault - 이벤트 기본 정보 (전자상거래 단계명 및 이벤트 매개변수)
 *
 * @property {String} [event] - 이벤트 제목...?
 * @property {String} [ep_event_page] - 이벤트 페이지
 * @property {String} [ep_event_area] - 이벤트 영역
 * @property {?String} [ep_event_label] - 이벤트 라벨
 **/

/**
 * @typedef {Object} ECommerce - 전자상거래 정보 매개변수 묶음
 *
 * @property {?string} [currency="KRW"] - 통화
 * @property {?string} [transaction_id] - 거래 ID
 * @property {?string} [transaction_ltk_id] - LTK 거래 ID
 * @property {?number} [value] - 전자상거래 수익
 * @property {?string} [payment_type] - 결제 방법
 * @property {?string} [coupon] - 주문 쿠폰
 * @property {?Item[]} [items] - 상품 리스트
 **/

/**
 * @typedef {Object} Item - 상품 정보 매개변수 묶음
 * @property {?string} id - 항목 ID(상품 SKU)
 * @property {?string} name - 항목 이름(상품 이름)
 * @property {?number} quantity - 상품 수량
 * @property {?number} price - 상품 수익
 * @property {?string} [brand] - 상품 브랜드
 * @property {?string} [category] - 항목 카테고리(상품 카테고리)
 * @property {?string} [category2] - 상품 카테고리2
 * @property {?string} [category3] - 상품 카테고리3
 * @property {?string} [category4] - 상품 카테고리4
 * @property {?string} [category5] - 상품 카테고리5
 * @property {?string} [coupon] - 상품 쿠폰
 * @property {?string} [coupon_name] - 상품 쿠폰명
 * @property {?string} [variant] - 항목 대안(위탁/자사 상품 구분)
 * @property {?string} [season] - 상품 시즌
 * @property {?string} [discount] - 상품 할인 금액(정상가)
 * @property {?string} [discount_rate] - 상품 할인율
 * @property {?string} [brand_id] - 상품 브랜드 ID
 * @property {?string} [purchasing_method] - 상품 구매방법
 */
 /**
 * @typedef {Object} Item_GA - 상품 정보 매개변수 묶음
 * @property {?string} item_id - 항목 ID(상품 SKU)
 * @property {?string} item_name - 항목 이름(상품 이름)
 * @property {?number} quantity - 상품 수량
 * @property {?number} price - 상품 수익
 * @property {?string} [item_brand] - 상품 브랜드
 * @property {?string} [item_category] - 항목 카테고리(상품 카테고리)
 * @property {?string} [item_category2] - 상품 카테고리2
 * @property {?string} [item_category3] - 상품 카테고리3
 * @property {?string} [item_category4] - 상품 카테고리4
 * @property {?string} [item_category5] - 상품 카테고리5
 * @property {?string} [coupon] - 상품 쿠폰
 * @property {?string} [discount] - 상품 할인 금액(정상가)
 * @property {?string} [item_variant] - 항목 대안(위탁/자사 상품 구분)
 * @property {?string} [ip_product_season] - 상품 시즌
 * @property {?string} [ip_product_discountrate] - 상품 할인율
 * @property {?string} [ip_product_brandid] - 상품 브랜드ID
 * @property {?string} [ip_product_purchasingmethod] - 상품 구매방법
 * @property {?string} [ip_product_couponname] - 상품 쿠폰명
 */

/**
 * @typedef {'상품상세진입' | '장바구니진입' | '주문취소완료' | '주문결제진입' | '주문완료진입' | '상품상세찜' | '상품목록찜' | '장바구니삭제' | '결제하기클릭' | '장바구니구매' | '장바구니찜' | '장바구니담기' | '상품상세구매'} ECOMMERCE_EVENT_TYPE
 * @typedef {{GA: String | WL: String}} ECOMMERCE_EVENT
 * @type {Record<ECOMMERCE_EVENT_TYPE, ECOMMERCE_EVENT>}
 **/
const ECOMMERCE_EVENTS = {
    상품상세진입: {
        GA: "view_item",
        WL: "view_product",
    },
    상품상세찜: {GA: "add_to_wishlist_A"},
    장바구니찜: {GA: "add_to_wishlist_B"},
    상품목록찜: {GA: "add_to_wishlist_C"},
    장바구니담기: {GA: "add_to_cart"},
    장바구니진입: {GA: "view_cart"},
    장바구니삭제: {GA: "remove_from_cart"},
    상품상세구매: {GA: "begin_checkout_A"},
    장바구니주문: {GA: "begin_checkout_B"},
    주문결제진입: {GA: "add_shipping_info"},
    결제하기클릭: {GA: "add_payment_info"},
    주문완료: {GA: "purchase"},
    주문취소완료: {GA: "refund"},
}
/**
 * @typedef {'view_item' | 'add_to_wishlist_A' | 'add_to_wishlist_B' | 'add_to_wishlist_C' | 'add_to_cart' | 'view_cart' | 'remove_from_cart' | 'begin_checkout_A' | 'begin_checkout_B' | 'add_shipping_info' | 'add_payment_info' | 'purchase' | 'refund'} ECOMMERCE_EVENT_TYPE_GA
 * @type {{ECOMMERCE_EVENT_TYPE_GA, Array}}
 */
const ECOMMERCE_EVENT_MAPPING_GA = {
    view_item: ["view_item", "Ecommerce", "view_item", null],
    add_to_wishlist_A:     ["add_to_wishlist", "Ecommerce", "add_to_wishlist", "상품상세"],
    add_to_wishlist_B:     ["add_to_wishlist", "Ecommerce", "add_to_wishlist", "장바구니"],
    add_to_wishlist_C:     ["add_to_wishlist", "Ecommerce", "add_to_wishlist", "상품목록"],
    add_to_cart:   ["add_to_cart", "Ecommerce", "add_to_cart", null],
    view_cart:   ["view_cart", "Ecommerce", "view_cart", null],
    remove_from_cart:   ["remove_from_cart", "Ecommerce", "remove_from_cart", null],
    begin_checkout_A:   ["begin_checkout", "Ecommerce", "begin_checkout", "상품상세"],
    begin_checkout_B:   ["begin_checkout", "Ecommerce", "begin_checkout", "장바구니"],
    add_shipping_info:   ["add_shipping_info", "Ecommerce", "add_shipping_info", null],
    add_payment_info:   ["add_payment_info", "Ecommerce", "add_payment_info", null],
    purchase:   ["purchase", "Ecommerce", "purchase", null],
    refund:   ["refund", "Ecommerce", "refund", null],
}

/**
 * @description 모든 key 를 가지고 있는 [전자상거래] 객체 반환
 * @param {ECommerce} filledECommerceInfo
 * @returns {ECommerce}
 **/
function baseEcommerceInfo(filledECommerceInfo) {
    /** @type {ECommerce} **/
    const BASE_ECOMMERCE = {
        currency: null,
        transaction_id: null,
        transaction_ltk_id: null,
        value: null,
        payment_type: null,
        coupon: null,
        items: [],
    }
    /** @type {ECommerce} **/
    return removeEmptyProperties(Object.assign({}, Object.freeze(BASE_ECOMMERCE), filledECommerceInfo));
}

/** @param {Item} filledItemInfo - 입력 받을 상품 정보
 * @returns {Item_GA} */
function baseItemInfo(filledItemInfo) {
    const GA_Keys = {
        id : "item_id",
        name: "item_name",
        quantity: "quantity",
        price: "price",
        brand: "item_brand",
        category: "item_category",
        category2: "item_category2",
        category3: "item_category3",
        category4: "item_category4",
        category5: "item_category5",
        coupon: "coupon",
        coupon_name: "ip_product_couponname",
        discount: "discount",
        variant: "item_variant",
        season: "ip_product_season",
        discount_rate: "ip_product_discountrate",
        brand_id: "ip_product_brandid",
        purchasing_method: "ip_product_purchasingmethod",
    }
    const renamedItemInfo = renameKeys(filledItemInfo, GA_Keys);
    /** @type {Item_GA} **/
    const BASE_ITEM = {
        item_id: null,
        item_name: null,
        quantity: null,
        price: null,
        item_brand: null,
        item_category: null,
        item_category2: null,
        item_category3: null,
        item_category4: null,
        item_category5: null,
        coupon: null,
        discount: null,
        item_variant: null,
        ip_product_season: null,
        ip_product_discountrate: null,
        ip_product_brandid: null,
        ip_product_purchasingmethod: null,
        ip_product_couponname: null,
    }
    /** @type {Item_GA} **/
    return removeEmptyProperties(Object.assign({}, BASE_ITEM, renamedItemInfo));
}

/**
 * @description 주문 완료 시에만 추가 되는 properties
 *
 * @typedef {Object} Purchase - 구매 이벤트 기본 정보
 * @property {?String} [purchase_method] - 결제 수단
 * @property {?String} [purchase_coupon_name] - 결제:쿠폰명
 * @property {?String} [shipping_method] - 결제:배송 방법
 * @property {?String} [shipping_coupon_code] - 결제:배송비 쿠폰 코드
 * @property {?String} [shipping_coupon_name] - 결제:배송비 쿠폰명
 */
 /**
 * @typedef {Object} Purchase_GA - 구매 이벤트 기본 정보
 * @property {?String} [ep_purchase_shippingcouponcd] - 결제:배송비 쿠폰 코드
 * @property {?String} [ep_purchase_shippingmethod] - 결제:배송 방법
 * @property {?String} [ep_purchase_couponname] - 결제:쿠폰명
 * @property {?String} [ep_purchase_shippingcoupon] - 결제:배송비 쿠폰명
 * @property {?String} [ep_purchase_method] - 결제 수단
 */

/**
 * @param {String} eventValues - [0]event  [1]ep_event_page  [2]p_event_area  [3]ep_event_label
 * @returns {EventDefault} - event, ep_event_page, ep_event_area, ep_event_label 객체
 */
function baseEventInfo(eventValues) {
    /** @type {EventDefault} */
    return {
        event: eventValues[0],
        ep_event_page: eventValues[1],
        ep_event_area: eventValues[2],
        ep_event_label: eventValues[3],
    }
}

/**
 * @param {Purchase} filledPurchaseInfo - 구매 정보
 * @returns {Purchase_GA}
 */
function basePurchaseInfo(filledPurchaseInfo) {
    const GA_keys = {
        purchase_method: "ep_purchase_method",
        purchase_coupon_name: "ep_purchase_couponname",
        shipping_method: "ep_purchase_shippingmethod",
        shipping_coupon_code: "ep_purchase_shippingcouponcd",
        shipping_coupon_name: "ep_purchase_shippingcoupon",
    }
    /** @type {Purchase_GA} **/
    const BASE_PURCHASE = {
        ep_purchase_shippingcouponcd: null,
        ep_purchase_shippingmethod: null,
        ep_purchase_couponname:  null,
        ep_purchase_shippingcoupon: null,
        ep_purchase_method: null
    }
    const renamedPurchaseInfo = renameKeys(filledPurchaseInfo, GA_keys);
    /** @type {Purchase_GA} */
    return Object.assign({}, BASE_PURCHASE, renamedPurchaseInfo);
}

/** @description 전자상거래 이벤트 관련 프로퍼티 모두 null 로 초기화 */
function initEcommerceEvent() {
    dataLayer.push({
        ecommerce: null,
        ep_event_page: null,
        ep_event_area: null,
        ep_event_label: null,
        ep_purchase_shippingcouponcd: null,
        ep_purchase_shippingmethod: null,
        ep_purchase_couponname: null,
        ep_purchase_shippingcoupon: null,
    });
}

/**
 * @param {ECOMMERCE_EVENT} eCommerceType
 * @param {ECommerce} eCommerceInfo
 * @param {Purchase_GA} [purchaseInfo]
 */
function pushEcommerceEvent(eCommerceType, eCommerceInfo, purchaseInfo) {
    const eventTypeForGA4 = ECOMMERCE_EVENT_MAPPING_GA[eCommerceType.GA];
    const addedBaseEcommerceEvent = addBaseEcommerceEvent(eventTypeForGA4, eCommerceInfo, purchaseInfo);
    push(addedBaseEcommerceEvent);
    initEcommerceEvent();
}

/**
 * @param {ECOMMERCE_EVENT_TYPE_GA} eventType
 * @param {ECommerce} eCommerceInfo
 * @param {Purchase_GA} purchaseInfo
 */
function addBaseEcommerceEvent(eventType, eCommerceInfo, purchaseInfo) {
    let eventObject = baseEventInfo(eventType);
    eventObject = Object.assign({}, eventObject, purchaseInfo);
    const ecommerceObject = {
        ecommerce: eCommerceInfo
    };
    return Object.assign(eventObject, ecommerceObject);
}

function renameKeys(oldObject, newKeys) {
    const entries = Object.keys(oldObject).map(key => {
        const newKey = newKeys[key] || key;
        return {[newKey]: oldObject[key]};
    });
    return Object.assign({}, ...entries);
}

/** @enum {String} EVENT_TYPE **/
const EVENT_TYPE = {}

/**
 * @param {EVENT_TYPE} eventType
 * @param {ECOMMERCE_EVENT} eCommerceEvent
 */
function pushEvent(eventType, eCommerceEvent) {
    /*
    페이지뷰 or 전자상거래인지?
    전자상거래라면 ? 전자상거래 중 어떤 이벤트인지?
    데이터는?
     */
    // push(data);
}


/*********************** SAMPLE ***********************/
/**
[javascript 에러 방지]
if (typeof pushEcommerceEvent === "function") { -> pushEcommerceEvent 함수가 있는지 확인
    try {
        pushEcommerceEvent(...)
    } catch(error) {
        // 예외처리
    }
} else {
    // 예외처리
}
 등의 방법을 사용하여 GA4 관련 스크립트 에러로 인한, 화면의 주요 function 들의 멈춤을 방지합니다.
**/

function 주문완료페이지들어왔을때() {
    // push 실행
    pushEcommerceEvent( // 상품 오브젝트 생성 함수: baseItemInfo(Object);
        ECOMMERCE_EVENTS.주문완료, // ECOMMERCE_EVENTS: 전자상거래 이벤트 타입 Enum
        baseEcommerceInfo({ // 전자상거래 오브젝트 생성 함수: baseEcommerceInfo(Object);
            // property 의 이름은 공통 정의로, GA4 정의와 다를 수 있습니다
            // jsdoc 으로 기술된 명세 확인 하거나 Ctrl+Space 로 오브젝트에 들어가야할 key 값을 찾을 수 있습니다
            items: 상품맵핑() // items 프로퍼티에 배열로 들어가야함.
        }),
        basePurchaseInfo({ // 구매 오브젝트 생성 함수: basePurchaseInfo(Object);
            purchase_method: "구매수단",
            shipping_method: "배송 수단",
        })
    );
}

function 주문취소완료시() {
    pushEcommerceEvent(
        ECOMMERCE_EVENTS.주문취소완료,
        baseEcommerceInfo({
            items: 상품맵핑(),
            currency: "KRW",
        })
    )
}

/** @returns {Array} */
function 상품맵핑() { // 상품 오브젝트 생성 함수: baseItemInfo(Object);
    const 개발자가조회해온상품목록 = ["a","b"];
    let items = [];
    // 상품 추가
    for (const 데이터 of 개발자가조회해온상품목록) {
        const item = baseItemInfo({
            id: "상품번호", // 데이터.상품번호
            name: "상품명", //데이터.상품명
            price: 100, // 데이터.가격
            quantity: 1, // 데이터.수량
            season: "SS23", // ip_product_season 상품 시즌
            discount_rate: "37%", // ip_product_discountrate 상품 할인율
            // 등등...
        });
        items.push(item);
    }
    return items;
}